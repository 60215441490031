import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useState } from 'react'
import { copySvg, copyThemeSvg, simpleCopySvg, successRoundSvg } from 'assets'
import { CheckOutlined } from '@ant-design/icons'

export default function Copy({
  height = '17.5px',
  width = '17.5px',
  text,
  icon = 'default',
  charactersCopy,
  onClick,
  isNotBubbling,
  className
}: {
  height?: string
  width?: string
  text: string | undefined
  icon?: 'default' | 'theme' | 'simple' | React.ReactNode
  charactersCopy?: string
  onClick?: () => void
  isNotBubbling?: boolean
  className?: string | undefined
}) {
  const [copied, setCopied] = useState(false)

  const onCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }

  const handleClick = (e) => {
    if (isNotBubbling) {
      e.stopPropagation()
    } else {
      onClick && onClick()
    }
  }

  return (
    <CopyToClipboard text={text || ''} onCopy={onCopy}>
      <div onClick={handleClick} className={'cursor-pointer'}>
        {copied ? (
          <img
            src={successRoundSvg}
            style={{
              height,
              width,
              maxWidth: width
            }}
          ></img>
        ) : (
          // <CheckOutlined
          //   className="flex items-center justify-center text-green-500"
          //   style={{
          //     height,
          //     width,
          //     maxWidth: width
          //   }}
          // />
          <>
            {icon === 'default' && (
              <img
                onClick={onClick}
                src={copySvg}
                className="cursor-pointer"
                style={{
                  height,
                  width,
                  maxWidth: width
                }}
              />
            )}
            {icon === 'theme' && (
              <img
                onClick={onClick}
                src={copyThemeSvg}
                className="cursor-pointer"
                style={{
                  height,
                  width,
                  maxWidth: width
                }}
              />
            )}
            {icon === 'simple' && (
              <img
                onClick={onClick}
                src={simpleCopySvg}
                className="cursor-pointer"
                style={{
                  height,
                  width,
                  maxWidth: width
                }}
              />
            )}
            {charactersCopy && <div>{charactersCopy}</div>}
            {icon !== 'default' &&
              icon !== 'theme' &&
              icon !== 'simple' &&
              icon !== 'none' &&
              icon}
          </>
        )}
      </div>
    </CopyToClipboard>
  )
}
