import { useEffect, useState } from 'react'
import userStore from '..'
import { reaction } from 'mobx'

const useUserStore = () => {
  const [user, setUser] = useState(() => userStore.userInfo)
  const [tgData, setData] = useState(() => userStore.tgData)
  const [userState, setUserState] = useState(() => userStore.userState)
  const [biometry, setBiometry] = useState(() => userStore.biometry)
  const [pageRoute, setPageRoute] = useState(() => userStore.pageRoute)
  const [autoLogin, setAutoLogin] = useState(() => userStore.autoLogin)
  useEffect(() => {
    const infoReaction = reaction(
      () => userStore.userInfo,
      (v) => {
        setUser(v)
      }
    )
    const tgDataReaction = reaction(
      () => userStore.tgData,
      (v) => setData(v)
    )
    const stateReaction = reaction(
      () => userStore.userState,
      (v) => setUserState(v)
    )
    const biometryReaction = reaction(
      () => userStore.biometry,
      (v) => setBiometry(v)
    )
    const pageReaction = reaction(
      () => userStore.pageRoute,
      (v) => setPageRoute(v)
    )
    const autoReaction = reaction(
      () => userStore.autoLogin,
      (v) => setAutoLogin(v)
    )
    return () => {
      infoReaction()
      tgDataReaction()
      stateReaction()
      biometryReaction()
      pageReaction()
      autoReaction()
    }
  }, [])
  return {
    user,
    tgData,
    userState,
    biometry,
    pageRoute,
    autoLogin
  }
}

export default useUserStore
