import qs from 'qs'

const sdkHashKey = 'sdkhash'
export function getSdkHash() {
  return sessionStorage.getItem(sdkHashKey)
}
export function setSdkHash(hash: string) {
  return sessionStorage.setItem(sdkHashKey, hash)
}

// {method,params:[salt,hash,signature]}
export function decodeTelegramUrlParameters(
  encodedParameters: string,
  isObject: boolean = true
) {
  const decodedParams = encodedParameters
    .replaceAll('--', '%')
    .replaceAll('__', '=')
    .replaceAll('-', '&')
    .replaceAll('%5F', '_')
    .replaceAll('%2D', '-')
    .replaceAll('%2E', '.')
  if (isObject) return qs.parse(decodedParams)
  return decodedParams
}

export function decodeBase64StartParam(encodedParameters: string) {
  // 假设 base64Encoded 是已经编码的 Base64 字符串
  const base64Encoded = encodedParameters // 示例字符串

  // 1. 使用 atob 解码 Base64 为二进制字符串
  const binaryString = atob(base64Encoded)

  // 2. 将二进制字符串转换回 Uint8Array
  const utf8Array = new Uint8Array(
    binaryString.split('').map((char) => char.charCodeAt(0))
  )

  // 3. 将 Uint8Array 转回 JSON 字符串
  const base64ContentStr = new TextDecoder().decode(utf8Array)

  // 4. 解析 JSON 字符串
  const base64Content = JSON.parse(base64ContentStr)

  // 输出结果
  // console.log('解码后的内容:', base64Content)
  return base64Content
}

export function stringify(obj: any) {
  const params = new URLSearchParams()

  function buildParams(prefix: string, value: any) {
    if (Array.isArray(value)) {
      value.forEach((v, i) => {
        if (/\[\]$/.test(prefix)) {
          params.append(prefix, v)
        } else {
          buildParams(`${prefix}[${typeof v === 'object' ? i : ''}]`, v)
        }
      })
    } else if (typeof value === 'object') {
      for (const key in value) {
        buildParams(`${prefix}[${key}]`, value[key])
      }
    } else {
      params.append(prefix, value)
    }
  }

  for (const key in obj) {
    buildParams(key, obj[key])
  }

  return params.toString()
}

export function encodeTelegramUrlParameters(
  parameters: any,
  isObject: boolean = true
) {
  if (isObject) {
    parameters = stringify(parameters)
    //  parameters = qs.stringify(parameters)
  }
  return parameters
    .replaceAll('.', '%2E')
    .replaceAll('-', '%2D')
    .replaceAll('_', '%5F')
    .replaceAll('&', '-')
    .replaceAll('=', '__')
    .replaceAll('%', '--')
}

export function decodePayload(base64: string) {
  const decodedBuffer = Buffer.from(base64, 'base64')
  const decodedStr = decodedBuffer.toString('utf-8')
  return JSON.parse(decodedStr)
}
