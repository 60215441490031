import { ErrorEvent, EventHint } from '@sentry/react'

export const beforeSendSentryError = (event: ErrorEvent, hint: EventHint) => {
  let tag = ''
  if (typeof event?.tags === 'object') {
    //【tag1】【tag2】
    tag = `【${Object.values(event.tags).join('】【')}】`
  }
  const pathname = window.location.pathname
  event?.exception?.values?.forEach((value) => {
    if (value?.value) {
      value.value = `【${pathname}】${tag}${value.value}`
    }
  })
  return event
}

export const isOauthPage = () => window.location.pathname.includes('/oauth')
