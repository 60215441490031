// type="module"
import React, { lazy, Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import VConsole from 'vconsole'
import { PageLoading } from './components/PageLoading'
import 'react-toastify/dist/ReactToastify.css'
import './index.css'
import App from './App'
import { ConfigProvider } from 'antd-mobile'
import enUS from 'antd-mobile/es/locales/en-US'
import 'tailwindcss/tailwind.css'
import * as Sentry from '@sentry/react'
import './i18n/i18n'
import './utils/firebase'
import { beforeSendSentryError } from './utils/sentry/utils'
import {
  SENTRY_DSN,
  SENTRY_DENY_URLS,
  SENTRY_SAMPLE_RATE,
  SENTRY_IGNORE_ERRORS,
  SENTRY_TRACES_SAMPLE_RATE
} from './utils/sentry/const'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'

if (import.meta.env.VITE_NODE_ENV !== 'location') {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ],
    sampleRate: SENTRY_SAMPLE_RATE,
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    denyUrls: SENTRY_DENY_URLS,
    // Tracing
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    environment: import.meta.env.VITE_NODE_ENV,
    beforeSend: beforeSendSentryError
  })
}

const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)

// console.log('env', import.meta.env)
if (import.meta.env.VITE_CONSOLE == '1') {
  import('vconsole').then(({ default: VConsole }: any = {}) => {
    console.log('init vconsole')
    new VConsole()
    console.log('init vconsole finish')
  })
}
;(async () => {
  const response = await fetch('/ramp/achramp-risk-sdk.min.js')
  const text = await response.text()
  const blob = new Blob([text], { type: 'application/javascript' })
  const script = document.createElement('script')
  script.src = URL.createObjectURL(blob)
  document.body.appendChild(script)
  await new Promise((resolve, reject) => {
    script.onload = resolve
    script.onerror = reject
  })
})()

// const App = lazy(() => import('App'))
root.render(
  <Suspense fallback={<PageLoading />}>
    <ConfigProvider locale={enUS}>
      <App />
    </ConfigProvider>
  </Suspense>
)
