import { getPayload as getPayloadApi } from 'api/sdk'
import { decodePayload } from 'utils/sdk'

export default function useSdk() {
  const getPayload = async () => {
    try {
      const { payload_base64 } = await getPayloadApi()
      return decodePayload(payload_base64)
    } catch (err: any) {
      console.log('err', err)
      throw err
      // return err?.response?.data?.message || err?.message || ''
    }
  }

  return { getPayload }
}
