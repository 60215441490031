import { sdkReject as sdkRejectApi } from '@/api/sdk'
import toast from 'components/Toast'

export default function useWallet() {
  const sdkReject = async () => {
    try {
      await sdkRejectApi()

      return true
    } catch (err: any) {
      toast.error(err?.response?.data?.message || err?.message || '')
      return false
    }
  }

  return { sdkReject }
}
