import { mockTonChainId, mockTonTestnetChainId } from '@/config/ton'
import { TonTxRequestStandard as TonTxRequestStandardSdk } from '@tomo-inc/tomo-telegram-sdk'
import { useMfa } from '@/hooks/useMfa'
import { tonSignSdkTransaction } from '@/api/sdk'
import { setPassKey } from 'utils'
import toast from 'components/Toast'
import { createSigningTransactionPure } from '@/config/oauth/ton'
import { createSigningTransactionPure as createSigningTonTestnetTransactionPure } from '@/config/oauth/tonTestnet'

export function useTonTx({ chainId }: { chainId?: number | undefined }) {
  const { getMfaParams } = useMfa()
  const signTonTransaction = async ({
    paramsForPure
  }: {
    paramsForPure?: TonTxRequestStandardSdk
  }) => {
    try {
      let singingMessage: Awaited<
        ReturnType<typeof createSigningTransactionPure>
      >
      if (paramsForPure)
        singingMessage =
          chainId === mockTonTestnetChainId
            ? await createSigningTonTestnetTransactionPure(paramsForPure)
            : await createSigningTransactionPure(paramsForPure)
      else throw 'sign ton tx by json payload is deprecated'
      if (!singingMessage?.signingMessageBoc) return

      const mfaRes = await getMfaParams({
        content: singingMessage,
        chainid: chainId || mockTonChainId
      })

      const { mfa } = mfaRes
      if (!mfa) return

      setPassKey(mfa)

      const mfaParams = {
        signingMessageBoc: singingMessage.signingMessageBoc,
        stateInitBoc: singingMessage?.stateInitBoc || '',
        isTestnet: chainId === mockTonTestnetChainId
      }

      const {
        result: signedTransaction,
        code,
        message
      } = await tonSignSdkTransaction(mfa, mfaParams)

      if (+code !== 10000) throw new Error(message)

      return {
        signedTransaction,
        code,
        message
      }
    } catch (error: any) {
      console.warn({
        error
      })
      if (error.name === 'EstimateGasExecutionError') {
        toast.error(error.details)
      } else {
        toast.error(error?.message || error.details || error)
      }
    }
  }
  return {
    signTonTransaction
  }
}
