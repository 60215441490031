import { AxiosInstance, AxiosResponse } from 'axios'
import { isOauthPage } from './utils'
import { pick } from '@/components/tmd/utils/base'
import { sentryCaptureException } from './sentryCaptureException'
import {
  SENTRY_OAUTH_HTTP_IGNORE_ERROR_STATUS,
  SENTRY_HTTP_SUCCESS_BIZ_CODE
} from './const'
import {
  createSentrySpan,
  endSentrySpan,
  handleSentrySpanError
} from './handleSentrySpan'

export const sentryInterceptors = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    try {
      createSentrySpan(config)
    } catch (error) {
      sentryCaptureException(<Error>error)
    }
    return config
  })
  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      try {
        endSentrySpan(response)
        // Interface business error reporting to Sentry
        const { code, msg, message } = response?.data || {}
        if (code && !SENTRY_HTTP_SUCCESS_BIZ_CODE.includes(Number(code))) {
          const url = response?.config?.url || ''
          const _msg = msg || message || 'unknown'
          const errMsg = `【api】res biz error, url: ${url}; msg: ${_msg}; code: ${code};`
          sentryCaptureException(errMsg, {
            extra: {
              config: genExtraConfig(response?.config),
              res: response?.data
            }
          })
        }
      } catch (error) {
        sentryCaptureException(<Error>error)
      }
      return response
    },
    (error: Record<string, any>) => {
      try {
        handleSentrySpanError(error)
        const { config, response } = error || {}
        const url = config?.url || ''
        const status = response?.status || error?.status
        const code = error?.code || error?.status
        const msg = response?.data?.message || error?.message
        const noErrorInfo = !status && !code && !msg
        const ignoreError =
          isOauthPage() &&
          SENTRY_OAUTH_HTTP_IGNORE_ERROR_STATUS.includes(Number(status))

        if (noErrorInfo || ignoreError) return Promise.reject(error)
        const errMsg = `【api】res error, url: ${url}; msg: ${msg}; code: ${code}; status: ${status};`
        sentryCaptureException(errMsg, {
          extra: genErrorExtra(error)
        })
      } catch (error) {
        sentryCaptureException(<Error>error)
      }
      return Promise.reject(error)
    }
  )
}

const genErrorExtra = (error: Record<string, any> = {}) => {
  return {
    config: genExtraConfig(error?.config),
    error: pick(error, ['code', 'name', 'message', 'stack'])
  }
}
const genExtraConfig = (config: Record<string, any> = {}) => {
  return pick(config, [
    'baseURL',
    'data',
    'params',
    'headers',
    'method',
    'timeout',
    'url'
  ])
}
