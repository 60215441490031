import { lazy, PropsWithChildren, useEffect } from 'react'
import { createBrowserRouter, RouteObject, useNavigate } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import GuardRoute from './GuardRoute'
import ErrorBoundary from './ErrorBoundary'
import LaunchPage from '@/pages/login/Launch'
import Oauth from '@/pages/oauth/index'
import walletStore from '@/stores/walletStore'
import { PageLoading } from '@/components/PageLoading'
import {
  // CardDetails,
  ChoosePaymentMethod,
  ConfirmPayment,
  HistoryPage,
  OrderStatus,
  // PaymentStatus,
  SelectCurrency
  // TradeView,
} from '@/pages/ramp'
import Union from '@/pages/union'
import userStore from '@/stores/userStore'
import useUserStore from '@/stores/userStore/hooks/useUserStore'
import useApp from '@/hooks/oauth/useApp'

const SignTonTx = lazy(() => import('@/pages/ton-connect/TonTransfer'))
const MarketSearchToken = lazy(() => import('@/pages/market/MarketSearchToken'))
// 使用 lazy 加载需要懒加载的组件
const HomeLayout = lazy(() => import('layout/HomeLayout'))
const Home = lazy(() => import('@/pages/Home'))
const RecoveryEmail = lazy(() => import('@/pages/login/RecoveryEmail'))
const Test = lazy(() => import('pages/Test'))
const MarketToken = lazy(() => import('pages/market/Detail'))
const Market = lazy(() => import('pages/market/Market'))
const SwapPage = lazy(() => import('pages/SwapPage'))
const Task = lazy(() => import('pages/task/Task'))
const UserOptions = lazy(() => import('pages/wallet/UserOptions'))
const SelectSendToken = lazy(
  () => import('@/pages/token/sendToken/SelectSendToken')
)
const SelectBtcAddressType = lazy(
  () => import('@/pages/token/selectBtcAddressType')
)
const InputAddress = lazy(() => import('@/pages/token/sendToken/InputAddress'))
const InputAmount = lazy(() => import('@/pages/token/sendToken/InputAmount'))
const AccountAddress = lazy(() => import('pages/wallet/AccountAddress'))
const ConfirmSendInfo = lazy(
  () => import('pages/token/sendToken/ConfirmSendInfo')
)
const SendResult = lazy(() => import('pages/token/sendToken/SendResult'))
const ManageToken = lazy(() => import('@/pages/token/manageToken/index'))
const AddToken = lazy(() => import('@/pages/token/manageToken/AddToken'))

const GiftManageView = lazy(() => import('pages/gift/GiftManageView'))
const GiftDetailView = lazy(() => import('pages/gift/GiftDetailView'))
const GiftCreateView = lazy(() => import('pages/gift/GiftCreateView'))
const GiftQrCodeShareView = lazy(() => import('pages/gift/GiftQrCodeShareView'))
const GiftHistoryDetailView = lazy(
  () => import('pages/gift/GiftHistoryDetailView')
)

const ForgetPage = lazy(() => import('@/pages/login/Forget'))
const ChangePage = lazy(() => import('@/pages/login/Change'))
const VerifyPage = lazy(() => import('@/pages/login/Verify'))
const EmailPage = lazy(() => import('@/pages/login/EmailCurrent'))
const SettingPage = lazy(() => import('@/pages/login/Setting'))
const ThemePage = lazy(() => import('@/pages/login/Theme'))
const LanguagePage = lazy(() => import('@/pages/login/Language'))
const Freeze = lazy(() => import('@/pages/login/Freeze'))
const BackupPage = lazy(() => import('@/pages/login/components/BackupPage'))

const Layout = lazy(() => import('layout'))
const TokenDetail = lazy(() => import('pages/token/detail'))
const TokenReceive = lazy(() => import('pages/token/detail/Receive'))
const Manage = lazy(() => import('pages/Manage'))
const SelectReceiveToken = lazy(
  () => import('pages/token/detail/SelectReceiveToken')
)
const InviteNavigate = lazy(() => import('../pages/InviteNavigate'))
const ReferralPage = lazy(() => import('pages/referral'))
const SetPage = lazy(() => import('@/pages/login/Set'))
const BioPage = lazy(() => import('@/pages/login/Bio'))
const FriendsInvited = lazy(() => import('@/pages/friendsInvited'))
const SwapSelectTokenPage = lazy(
  () => import('@/pages/swap/SwapSelectTokenPage')
)
const History = lazy(() => import('@/pages/history'))
const HistoryDetail = lazy(() => import('@/pages/history/Detail'))
const SelectCardToken = lazy(() => import('pages/token/card/SelectCardToken'))

const MnemonicIndex = lazy(() => import('pages/manages/mnemonic/index'))
const MnemonicMain = lazy(() => import('pages/manages/mnemonic/main'))
const MnemonicCountdown = lazy(() => import('pages/manages/mnemonic/countdown'))
const MnemonicReceived = lazy(() => import('pages/manages/mnemonic/received'))
const MnemonicPhrase = lazy(() => import('pages/manages/mnemonic/phrase'))

const TradeView = lazy(() => import('pages/ramp/TradeView'))
const CardDetails = lazy(() => import('pages/ramp/CardDetails'))
const PaymentStatus = lazy(() => import('pages/ramp/PaymentStatus'))

const StarSwap = lazy(() => import('@/pages/starForGas/StarSwap'))
const StarSwapHistory = lazy(() => import('@/pages/starForGas/StarSwapHistory'))

const NetworkFeePage = lazy(() => import('@/pages/oauth/NetworkFee'))
const RawDataPage = lazy(() => import('@/pages/oauth/RawData'))
const Expired = lazy(() => import('@/pages/oauth/Expired'))

export type RouteConfig = RouteObject & {
  auth?: boolean
  children?: RouteConfig[]
}

export const routes: RouteConfig[] = [
  {
    path: '/',
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    auth: true,
    children: [
      {
        path: '/',
        element: <HomeLayout />,
        children: [
          {
            index: true,
            element: <Home store={walletStore} />,
            auth: true
          },
          {
            path: '/task',
            element: <Task />,
            auth: true
          },
          {
            path: '/swap',
            auth: false,
            children: [
              {
                path: '/swap/select-token',
                element: <SwapSelectTokenPage />
              },
              {
                index: true,
                element: <SwapPage />
              }
            ]
          },
          {
            path: '/history',
            children: [
              {
                path: '/history/detail',
                auth: true,
                element: <HistoryDetail />
              },
              {
                index: true,
                auth: true,
                element: <History />
              }
            ]
          },
          {
            path: '/market',

            auth: true,
            children: [
              {
                index: true,
                auth: true,
                element: <Market />
              },
              {
                path: '/market/search-token',
                element: <MarketSearchToken />,
                auth: true
              }
            ]
          },
          {
            path: '/send',
            auth: false,
            children: [
              {
                path: '/send/select-token',
                element: <SelectSendToken />,
                auth: true
              },
              {
                path: '/send/input-address',
                element: <InputAddress />,
                auth: true
              },
              {
                path: '/send/input-amount',
                element: <InputAmount store={walletStore} />,
                auth: true
              },
              {
                path: '/send/confirm-send',
                element: <ConfirmSendInfo />,
                auth: true
              }
            ]
          },
          {
            path: '/receive',
            auth: false,
            children: [
              {
                path: '/receive/select-token',
                auth: true,
                element: <SelectReceiveToken />
              }
            ]
          },
          {
            path: '/token/:chain/:address/receive/:wtype?',
            auth: true,
            element: <TokenReceive />
          },
          {
            path: '/card',
            auth: false,
            children: [
              {
                path: '/card/select-token',
                auth: true,
                element: <SelectCardToken />
              }
            ]
          },
          {
            path: '/sdk/sign-ton',
            auth: true,
            element: <SignTonTx />
          },
          {
            path: '/star',
            auth: false,
            children: [
              {
                path: '/star/swap',
                auth: true,
                element: <StarSwap />
              },
              {
                path: '/star/history',
                auth: true,
                element: <StarSwapHistory />
              }
            ]
          },
          {
            path: '/:from/result',
            element: <SendResult />,
            auth: true
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    children: [
      {
        index: true,
        element: <LaunchPage />
      },
      {
        path: '/login/verify',
        auth: false,
        element: <VerifyPage />
      },
      {
        path: '/login/email',
        auth: false,
        element: <EmailPage />
      },
      {
        path: '/login/forget',
        auth: false,
        element: <ForgetPage />
      },
      {
        path: '/login/set',
        auth: false,
        element: <SetPage />
      },
      {
        path: '/login/freeze',
        auth: false,
        element: <Freeze />
      },
      {
        path: '/login/backup',
        auth: false,
        element: <BackupPage />
      }
    ]
  },
  {
    path: '/oauth',
    children: [
      {
        index: true,
        element: <Oauth />,
        auth: false
      },
      {
        path: '/oauth/network-fee',
        element: <NetworkFeePage />,
        auth: false
      },
      {
        path: '/oauth/raw-data',
        element: <RawDataPage />,
        auth: false
      },
      {
        path: '/oauth/expired',
        element: <Expired />,
        auth: false
      }
    ]
  },
  {
    path: '/account',
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    children: [
      {
        index: true,
        element: <UserOptions />,
        auth: true
      },
      {
        path: '/account/manage-token',
        element: <ManageToken />,
        auth: true
      },
      {
        path: '/account/add-token',
        element: <AddToken />,
        auth: true
      },
      {
        path: '/account/address',
        element: <AccountAddress />,
        auth: true
      },
      {
        path: '/account/email',
        element: <EmailPage />,
        auth: false
      },
      {
        path: '/account/settings',
        auth: true,
        element: <SettingPage />
      },
      {
        path: '/account/language',
        auth: true,
        element: <LanguagePage />
      },
      {
        path: '/account/theme',
        auth: true,
        element: <ThemePage />
      },
      {
        path: '/account/recovery-email',
        auth: false,
        element: <RecoveryEmail />
      },
      {
        path: '/account/change',
        auth: true,
        element: <ChangePage />
      },
      {
        path: '/account/bio',
        auth: true,
        element: <BioPage />
      },
      {
        path: '/account/manage',
        element: <Manage />,
        auth: true
      }
    ]
  },
  {
    path: '/friends-invited',
    element: <FriendsInvited />,
    auth: true
  },
  {
    path: '/test',
    element: <Test />
  },
  {
    path: '/l/:inviteCode',
    element: <InviteNavigate />
  },

  {
    path: '/market/token/:chain/:token',
    auth: true,
    element: <MarketToken />
  },
  {
    path: '/mnemonic',
    auth: true,
    element: <MnemonicIndex />,
    children: [
      {
        path: '/mnemonic/main',
        element: <MnemonicMain />
      },
      {
        path: '/mnemonic/countdown',
        element: <MnemonicCountdown />
      },
      {
        path: '/mnemonic/received',
        element: <MnemonicReceived />
      },
      {
        path: '/mnemonic/phrase',
        element: <MnemonicPhrase />
      }
    ]
  },
  // {
  //   path: '/invite',
  //   element: <Invite />
  // },

  {
    path: '/referral',
    element: <ReferralPage />,
    auth: true
  },
  {
    path: '/token/:chain/:address',
    auth: true,
    children: [
      // {
      //   path: '/token/:chain/:address/send/:wtype?',
      //   element: <SendToken />
      // },
      {
        index: true,
        element: <TokenDetail />
      }
    ]
  },
  {
    path: '/:from/select-btc-type',
    element: <SelectBtcAddressType />,
    auth: true
  },
  {
    path: '/gift',
    auth: false,
    // element: (
    //   <ErrorBoundary>
    //     <Layout />
    //   </ErrorBoundary>
    // ),
    children: [
      {
        path: '/gift/manage',
        element: <GiftManageView />
      },
      {
        path: '/gift/:type/:id',
        element: <GiftDetailView />
      },
      {
        path: '/gift/history/:id/:type',
        element: <GiftHistoryDetailView />
      },
      {
        path: '/gift/share-qrcode/:id',
        element: <GiftQrCodeShareView />
      },
      {
        index: true,
        element: <GiftCreateView />
      }
    ]
  },
  {
    path: '/ramp',
    auth: true,
    element: (
      <ErrorBoundary>
        <Layout />
      </ErrorBoundary>
    ),
    children: [
      {
        path: '/ramp/trade',
        element: <TradeView />
      },
      {
        path: '/ramp/currency',
        element: <SelectCurrency />
      },
      {
        path: '/ramp/card-details',
        element: <CardDetails />
      },
      {
        path: '/ramp/confirm-payment/:lastFourDigits/:cardType',
        element: <ConfirmPayment />
      },
      {
        path: '/ramp/order-status',
        element: <OrderStatus />
      },
      {
        path: '/ramp/choose-payment-method',
        element: <ChoosePaymentMethod />
      },
      {
        path: '/ramp/payment-status/:merchantOrderNo',
        element: <PaymentStatus />
      },
      {
        path: '/ramp/history',
        element: <HistoryPage />
      }
    ]
  },
  {
    path: '/ramp/union',
    element: <Union />
  },
  {
    path: '/*',
    element: <PageLoading />
  }
]

function NotFound() {
  return <h1>Not Found</h1>
}

const JumpToOauthWrapper = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate()
  const { isValidActions, loading } = useApp()

  if (isValidActions) navigate('/oauth')

  return loading ? <PageLoading /> : <>{children}</>
}

const wrapWithGuard = (route: RouteConfig): RouteConfig => {
  const comp = <JumpToOauthWrapper>{route.element}</JumpToOauthWrapper>
  if (route.auth) {
    return {
      ...route,
      element: <GuardRoute>{comp}</GuardRoute>
    }
  }

  if (route.children) {
    return {
      ...route,
      children: route.children.map(wrapWithGuard)
    }
  }

  return route
}

const guardedRoutes = routes.map(wrapWithGuard)

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter)
export const routers = sentryCreateBrowserRouter(guardedRoutes)
