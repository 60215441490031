import useApp from '@/hooks/oauth/useApp'
import { getMethodInfo } from '@/utils/message'
import { BackButton } from '@vkruglikov/react-telegram-web-app'
import SignTonTx from './components/ui/SignTonTx'
import RequestAccounts from './components/ui/RequestAccounts'
import { NoResult } from '@/components/NoResult'
import SignTransaction from './components/ui/SignTransaction'
import userStore from '@/stores/userStore'
import { useNavigate } from 'react-router-dom'
import useUserStore from '@/stores/userStore/hooks/useUserStore'
import { useEffect, useMemo } from 'react'
import SignSolanaTransaction from './components/ui/SignSolanaTransaction'
import SignSuiTransaction from './components/ui/SignSuiTransaction'
import SignMessageUI from './components/ui/SignMessageUI'
import PersonalSign from './components/ui/PersonalSign'
import SignTransactionUILegacy from './components/ui/SignTransactionUILegacy'
import { useQuery } from '@tanstack/react-query'
import { PageLoading } from '@/components/PageLoading'
import useSdk from '@/hooks/oauth/useSdk'

export default function Oauth() {
  const { actions, webAppReject } = useApp()
  const [chainType, operation] = getMethodInfo(actions.method || '')

  const { getPayload } = useSdk()

  const {
    data: searchData,
    isFetched,
    error
  } = useQuery({
    queryKey: ['init-payload'],
    queryFn: async () => {
      return await getPayload()
    }
  })

  console.log('searchData ==>', searchData, error)

  const sdkSearchParams = useMemo(() => {
    if ((searchData?.data?.params || []).length) {
      const data = searchData?.data?.params[0] || {}
      return { ...data }
    }
    return {}
  }, [searchData?.data?.params])

  const navigate = useNavigate()
  const { pageRoute } = useUserStore()

  useEffect(() => {
    if (!pageRoute) return
    console.log('pageRoute ==>', pageRoute)
    navigate(pageRoute)
    userStore.updateRouteAction('')
  }, [pageRoute, navigate])

  useEffect(() => {
    if (
      !userStore?.userState?.email &&
      userStore?.userState?.setTradePassword &&
      isFetched
    ) {
      sdkSearchParams.email
        ? navigate('/account/recovery-email', {
            state: { email: sdkSearchParams.email }
          })
        : navigate('/account/recovery-email')
    }
  }, [navigate, sdkSearchParams.email, isFetched])

  if (error && (error as unknown as { status: number }).status === 401) {
    navigate('/oauth/expired')
  }

  if (!isFetched || !userStore?.userState?.email) {
    return <PageLoading />
  }

  const renderPageContent = () => {
    switch (actions.method) {
      case 'eth_requestAccounts':
        return <RequestAccounts />
      case 'personal_sign':
        return <PersonalSign />
      case 'eth_signTransaction':
      case 'eth_signETHTransaction':
      case 'eth_signErc20Transaction':
        return <SignTransaction />
      case 'ton_signTx':
        return <SignTonTx />
      case 'sol_signTx': // new sign tx, accepting transaction as param, for sdk at and after 1.0.14
        return <SignSolanaTransaction />
      case 'sui_signTransaction':
        return <SignSuiTransaction />
      default: // currently used for sol and ton
        if (operation === 'connectWallet') {
          return <RequestAccounts />
        } else if (
          // sol_transaction & sol_transfer, sol_transaction is for sdk before 1.0.14
          operation === 'signTransaction' ||
          operation === 'transfer'
        ) {
          return <SignTransactionUILegacy chainType={chainType as any} />
        } else if (operation === 'signMessage') {
          return <SignMessageUI chainType={chainType} />
        }
        return <NoResult emptyText="Not Found" containerClassName="mt-[20vh]" />
    }
  }

  console.log('actions ==>', actions)

  return (
    <>
      <BackButton
        onClick={() => {
          webAppReject(true)
        }}
      ></BackButton>
      {renderPageContent()}
      {/* <VerifyModal /> */}
    </>
  )
}
