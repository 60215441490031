import { Suspense } from 'react'
import * as Sentry from '@sentry/react'
import { RouterProvider } from 'react-router-dom'
import { routers, routes } from 'router'
import { WebAppProvider, useWebApp } from '@vkruglikov/react-telegram-web-app'
import { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import Providers from './proviers'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PageLoading } from './components/PageLoading'
import useInitTheme from './stores/userStore/hooks/useInitTheme'
import commonStore from './stores/commonStore'

//get env mode
function App() {
  const webapp = useWebApp()
  const queryClient = new QueryClient()
  useInitTheme()

  const initTMA = () => {
    webapp?.expand()
    webapp?.enableClosingConfirmation()
    webapp?.disableVerticalSwipes()
    const color = localStorage.theme === 'dark' ? '#12122a' : '#ffffff'
    webapp?.setHeaderColor(color)
    webapp?.setBackgroundColor(color)
    webapp?.setBottomBarColor(color)
  }

  useEffect(() => {
    window.initialUrlParams = location.search + location.hash.toString()
  }, [])

  useEffect(() => {
    initTMA()
    commonStore.updateStaticViewHeight(window.Telegram.WebApp.viewportHeight)
    const tgViewHeightChange = () => {
      console.log('update view', window.Telegram.WebApp.viewportHeight)
      commonStore.updateViewHeightChange(window.Telegram.WebApp.viewportHeight)
    }
    window.Telegram.WebApp.onEvent('viewportChanged', tgViewHeightChange)
    return () => {
      window.Telegram?.WebApp.offEvent('viewportChanged', tgViewHeightChange)
    }
  }, [])

  return (
    <Sentry.ErrorBoundary>
      <WebAppProvider
        options={{
          smoothButtonsTransition: true
        }}
      >
        <QueryClientProvider client={queryClient}>
          <Providers>
            <Suspense fallback={<PageLoading />}>
              <ToastContainer theme="dark" />
              <RouterProvider router={routers} />
            </Suspense>
          </Providers>
        </QueryClientProvider>
      </WebAppProvider>
    </Sentry.ErrorBoundary>
  )
}

export default Sentry.withProfiler(App)
