import { successCode, v2PkSignApi, v2PkValidateApi } from '../api'
import useBiometricManager from './useBiometricManager'
import { useDeviceId } from './useDeviceId'
import { Toast } from 'antd-mobile'
import { useAtom } from 'jotai'
import { isSendBioAuthAtom } from 'state'
import { errorContents } from 'config/const'
import usePaypinVerify from '@/pages/login/hooks/usePaypinVerify'
import useUserStore from '@/stores/userStore/hooks/useUserStore'
export const useMfa = () => {
  const biometricManager = useBiometricManager()
  const { create, getDeviceIdFromToken } = useDeviceId()

  const { biometry } = useUserStore()
  const { showPopup: showPaypinPopup } = usePaypinVerify()

  const [isSendBioAuth, setIsSendBioAuth] = useAtom(isSendBioAuthAtom)
  const signMessage = async (
    params: {
      content: any
      chainid: number
    },
    device_no: string
  ) => {
    const message = JSON.stringify(params.content)
    const signRes = await v2PkSignApi({
      message,
      device_no
    })
    if (!signRes) {
      throw new Error('sign key api failed')
    }
    if (signRes.code != successCode) {
      throw new Error(signRes.message)
    }
    const valRes = await v2PkValidateApi({
      device_no,
      message,
      sig: signRes.data.signature
    })
    if (!valRes) {
      throw new Error('validate passkey api failed')
    }
    if (valRes.code != successCode) {
      throw new Error(valRes.message)
    }
    return {
      signature: signRes.data.signature,
      mfa: valRes.data.key
    }
  }
  const getMfaParams = async (
    params: {
      content: any
      chainid: number
      faildCallBack?: (success: boolean) => void
    }
    // callback: (params: { signature: string; mfa: string }) => void
  ): Promise<{
    mfa: any
    signature: any
  }> => {
    return new Promise((resolve, reject) => {
      //check api verify
      try {
        if (!biometry.available || !biometry.token_saved) {
          const pop = showPaypinPopup()
          pop.promise
            .then((mfa) => {
              // console.log('onShow', mfa, pop)
              pop.close()
              resolve({
                mfa: mfa,
                signature: ''
              })
            })
            .catch((error) => {
              pop.close()
              reject(new Error(error))
            })

          return
        } else {
          try {
            // throw new Error('Biometric verify failed!!!!')
            biometricManager.authenticate({
              reason:
                'We will get you public key to signature your transaction',
              callback: async (success: boolean, token: string) => {
                if (!success) {
                  Toast.clear()

                  // reject(new Error('Biometric verify failed!'))
                  const pop = showPaypinPopup()
                  pop.promise
                    .then((mfa) => {
                      // console.log('onShow', mfa, pop)
                      pop.close()
                      resolve({
                        mfa: mfa,
                        signature: ''
                      })
                    })
                    .catch((error) => {
                      pop.close()
                      reject(new Error(error))
                    })
                  return
                }

                try {
                  const res = await signMessage(
                    params,
                    getDeviceIdFromToken(token)
                  )
                  setIsSendBioAuth(true)
                  resolve(res)
                } catch (e: any) {
                  reject(e?.response?.data?.message || e?.message)
                }
              },
              failCallback: () => {
                console.log({
                  key: 'Biometric verify failed'
                })
                Toast.clear()
                // throw new Error('Biometric verify failed!')
                const pop = showPaypinPopup()
                pop.promise
                  .then((mfa) => {
                    // console.log('onShow', mfa, pop)
                    pop.close()
                    resolve({
                      mfa: mfa,
                      signature: ''
                    })
                  })
                  .catch((error) => {
                    pop.close()
                    reject(new Error(error))
                  })

                return
              }
            })
          } catch (error) {
            const pop = showPaypinPopup()
            pop.promise
              .then((mfa) => {
                // console.log('onShow', mfa, pop)
                pop.close()
                resolve({
                  mfa: mfa,
                  signature: ''
                })
              })
              .catch((error) => {
                pop.close()
                reject(new Error(error))
              })

            return
          }
        }
      } catch (error) {
        console.log('errr', error)
        reject(errorContents.serverError)
      }
    })
  }
  return { getMfaParams, isSendBioAuth }
}
