// import { Config, getHttpEndpoint, Network } from '@orbs-network/ton-access'
import TonWeb, { AddressType } from 'tonweb'
import { TonTxRequestStandard } from '@tomo-inc/tomo-telegram-sdk'
import { fromNano } from '@ton/core'

export type TonSigningTransactionType = {
  fromAddress: string
  publicKey: string
  amount: string
  toAddress: string
  memo?: string
  tokenContractAddress?: string
  tokenPrecision?: number
  forwardAmount?: string
}

export const mockTonChainId = 1100
export const mockTonTestnetChainId = 1101
export const mockTonOkxChainID = 607
export const tonDecimals = 9

const apiKey: string =
  '227f87a614319d10e4c20f0f485cd101eca12989bf59da0e33b3c3da8ad534b2'
const tonRpc: string = 'https://testnet.toncenter.com/api/v2/jsonRPC'
const hashHttp: string = 'https://testnet.toncenter.com/api/index/v1'
// export const tonScanUrl: string = 'https://tonviewer.com/transaction/'

export async function getTonWebProvider() {
  return new TonWeb(new TonWeb.HttpProvider(tonRpc, { apiKey: apiKey }))
}

export const getTonBalance = async (tonAddress: string) => {
  // get the decentralized RPC endpoint
  const tonWeb = await getTonWebProvider()
  const wallet = tonWeb.wallet.create({ address: tonAddress }) // if your know only address at this moment
  // const wallet = tonWeb.wallet.create({publicKey: TonWeb.utils.hexToBytes(publicKey)});

  const address = await wallet.getAddress()

  const balance = await tonWeb.getBalance(address)

  return { balance, formatted: TonWeb.utils.fromNano(balance) }
}

export async function createSigningTransactionPure(
  transactionInfo: TonTxRequestStandard
) {
  try {
    const tonWeb = await getTonWebProvider()
    const WalletClass = tonWeb.wallet.all['v4R2']
    const wallet = new WalletClass(tonWeb.provider, {
      publicKey: tonWeb.utils.hexToBytes(transactionInfo.publicKey as string),
      wc: 0
    })
    // console.log('tonWallet ==>', window.tonWeb = tonWeb, window.tonWallet = wallet)
    const seqno = (await wallet.methods.seqno().call()) || 0
    let stateInit = null
    if (seqno == 0) {
      const deploy = await wallet.createStateInit()
      stateInit = deploy.stateInit
    }

    // let signingMessage
    let stateInitBoc
    let sendmode = 3
    const balance = await getTonBalance(transactionInfo.body.from)

    const msgRaw = transactionInfo.body.messages[0]

    /** Nano Ton */
    const transferTonAmount = msgRaw.amount.toString() // no 0.05 TON fallback

    if (
      Number(balance.formatted) - Number(fromNano(transferTonAmount)) <=
      0.01
    ) {
      sendmode = 128
    }
    let toAddress: AddressType = new TonWeb.utils.Address(
      msgRaw.address
    ).toString(true, true, true)
    const info = await tonWeb.provider.getAddressInfo(toAddress)
    if (info.state !== 'active') {
      toAddress = new TonWeb.utils.Address(toAddress).toString(
        true,
        true,
        false
      ) // convert to non-bounce
    }
    const payload = msgRaw.payload
      ? tonWeb.boc.Cell.oneFromBoc(msgRaw.payload) // restore cell from hex code
      : ''
    const externalMessage = await wallet.createTransferMessage(
      new Uint8Array(),
      toAddress,
      transferTonAmount,
      seqno,
      payload, // this need to be a tonWeb.boc.Cell,
      sendmode, // 3,
      true,
      stateInit as any
    )
    const cellBase64 = await externalMessage.signingMessage.toBoc(false)
    const signingMessage = tonWeb.utils.bytesToHex(cellBase64)
    const hash = await externalMessage.signingMessage.hash()
    if (stateInit) {
      const cellBase641 = await stateInit.toBoc(false)
      stateInitBoc = tonWeb.utils.bytesToHex(cellBase641)
    }
    return {
      signingMessageBoc: signingMessage,
      stateInitBoc
    }
  } catch (e) {
    console.error(e)
  }
}
