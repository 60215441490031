import sui from '@/proviers/web3Provider/chains/wagmiConfig/sui'

import { suiSignMessage, suiSignTransaction } from '@/api/sdk'
import { SendSuiTransactionParams } from '@/config/sui'
import { setPassKey } from '@/utils'
import { useMfa } from '../useMfa'

const useSuiTx = (
  { chainId = sui.id }: { chainId?: number } = {
    chainId: sui.id
  }
) => {
  const { getMfaParams } = useMfa()

  const signSuiTransaction = async (
    params: SendSuiTransactionParams
  ): Promise<{ result: string; code: number; message: string } | null> => {
    const { transactionBlock, chainId } = params
    const mfaRes = await getMfaParams({
      content: transactionBlock,
      chainid: chainId
    })

    const { mfa } = mfaRes
    if (!mfa) {
      return null
    }
    setPassKey(mfa)

    const signRes = await suiSignTransaction({
      mfa,
      params: {
        tx: transactionBlock
      }
    })
    return signRes
  }

  const signSuiMessage = async (params: {
    message: string
    walletId: number
  }) => {
    const mfaRes = await getMfaParams({
      content: params,
      chainid: chainId || sui.id
    })

    if (mfaRes.mfa) {
      setPassKey(mfaRes.mfa)

      const signRes = await suiSignMessage({
        params: params,
        mfa: mfaRes.mfa
      })

      return signRes
    }
  }

  return {
    signSuiTransaction,
    signSuiMessage
  }
}

export default useSuiTx
