import { useMutation } from '@tanstack/react-query'
import { execTonProof } from '@/api'
import useUserStore from '@/stores/userStore/hooks/useUserStore'
import { useInitData } from '@vkruglikov/react-telegram-web-app'
import { sdkGetDefaultWalletAddressApi, sdkLogin } from '@/api/sdk'

const useApprove = () => {
  const { userState } = useUserStore()
  const { userId } = userState
  const [, initData] = useInitData()

  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: () => sdkGetDefaultWalletAddressApi(userId)
  })

  const tonProofSign = async (tonProofParams: {
    domain: string
    payload: string
    workChain: number
  }) => {
    return await execTonProof(initData as string, tonProofParams)
  }

  /** the old login is for compatibility with the old version SDK */
  const {
    mutate: handleSdkLogin,
    isPending: oldLoginPending,
    isSuccess: oldLoginSuccess
  } = useMutation({
    mutationFn: () => sdkLogin(initData as string)
  })

  const {
    mutate: handleTonProofApprove,
    isPending: tonProofPending,
    isSuccess: tonProofSuccess
  } = useMutation({
    mutationFn: tonProofSign
  })

  return {
    handleApprove: mutate,
    handleSdkLogin,
    handleTonProofApprove,
    isPending: isPending || tonProofPending || oldLoginPending,
    isSuccess: isSuccess || tonProofSuccess || oldLoginSuccess
  }
}

export default useApprove
