import { getSdkHash } from '@/utils/sdk'
import { botServer, tomoSDKApi } from '.'
import { getPassKey, getUserInfo, logout } from 'utils'
import { IChainId } from '@/proviers/web3Provider/type'
import { errorContents } from '@/config/const'

export const sdkReject = async () => {
  const ret = await botServer.post(
    `sdk/reject`,
    {},
    {
      headers: {
        'tomo-session-id': getSdkHash()
      }
    }
  )
  return ret.data
}

export const getPayload = async () => {
  const res = await botServer.get('sdk/payload', {
    headers: {
      'tomo-session-id': getSdkHash()
    }
  })
  return res.data
}

export const signEvmMessage = async (
  mfa: string,
  data: {
    message: string
    walletId?: number
  }
) => {
  const { token } = getUserInfo()
  const res = await tomoSDKApi.post(
    'socialLogin/projectWallet/ethereum/signMessage',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa
      }
    }
  )
  return res.data
}

export const signSdkEvmTransaction = async (
  mfa: string,
  data: {
    transaction: {
      data: string | undefined
      gas: string | undefined
      gasPrice: string | undefined
      maxFeePerGas: string | undefined
      maxPriorityFeePerGas: string | undefined
      nonce: number | undefined
      to: string | undefined
      value: string | undefined
    }
    chainId: IChainId
  }
) => {
  const { token } = getUserInfo()
  const res = await tomoSDKApi.post(
    'socialLogin/projectWallet/ethereum/signTransaction',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa
      }
    }
  )
  return res.data
}

export const tonSignSdkTransaction = async (
  mfa: string,
  data: { signingMessageBoc: string; stateInitBoc: string }
) => {
  const { token } = getUserInfo()

  const res = await tomoSDKApi.post(
    '/socialLogin/projectWallet/ton/signTransaction',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        MFA: mfa
      }
    }
  )
  return res.data
}

export const solSignRawTransactionWithMFA = async ({
  mfa,
  data
}: {
  mfa: string
  data: any
}) => {
  const { token } = getUserInfo()
  const res = await tomoSDKApi.post(
    '/socialLogin/projectWallet/solana/signRawTransaction',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa
      }
    }
  )
  return res.data
}

export const solSignMessageWithMFA = async ({
  mfa,
  data
}: {
  mfa: string
  data: { message: string; walletId: number }
}) => {
  const { token } = getUserInfo()
  const res = await tomoSDKApi.post(
    '/socialLogin/projectWallet/solana/signMessage',
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        mfa: mfa
      }
    }
  )
  return res.data
}

export const suiSignMessage = async ({
  mfa,
  params
}: {
  mfa: string
  params: { message: string }
}) => {
  const { token } = getUserInfo()
  const { data } = await tomoSDKApi
    .post(`/socialLogin/projectWallet/sui/signPersonalMessage`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        MFA: mfa
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message
      return Promise.reject(message ? message : errorContents.transactionError)
    })
  return data
}

export const suiSignTransaction = async ({
  mfa,
  params
}: {
  mfa: string
  params: { tx: string }
}) => {
  const { token } = getUserInfo()
  const { data } = await tomoSDKApi
    .post(`/socialLogin/projectWallet/sui/signTransaction`, params, {
      headers: {
        Authorization: `Bearer ${token}`,
        MFA: mfa
      }
    })
    .catch((error) => {
      const message = error?.response?.data?.message
      return Promise.reject(message ? message : errorContents.transactionError)
    })
  return data
}

export const sdkGetDefaultWalletAddressApi = async (userId: number) => {
  const res = await tomoSDKApi.get(
    `socialLogin/projectWallet/getDefaultWalletByUserId?userId=${userId}`
  )
  return res.data
}

export const sdkLogin = async (query: string) => {
  const ret = await botServer.get(`sdk/login?${query}`, {
    headers: {
      'tomo-session-id': getSdkHash()
    }
  })
  return ret.data
}
