import { getTonWebProvider } from '@/config/ton'

export const getTonBalance = async (tonAddress: string) => {
  // get the decentralized RPC endpoint
  const tonWeb = await getTonWebProvider()
  const wallet = tonWeb.wallet.create({ address: tonAddress }) // if your know only address at this moment
  // const wallet = tonWeb.wallet.create({publicKey: TonWeb.utils.hexToBytes(publicKey)});

  const address = await wallet.getAddress()

  const balance = await tonWeb.getBalance(address)

  return { balance, formatted: tonWeb.utils.fromNano(balance) }
}
